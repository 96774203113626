export const HOME_URL = "/app/associates-list";

export const API_URL =
  "https://intellisafe-uat.onpointinsights.tech/backend/api/v1";

// export const API_URL = "https://login.intellisafeanalytics.com/backend/api/v1";

// export const API_URL = "http://127.0.0.1:8000/api/v1";

// export const API_URL = "https://login.intellisafeanalytics.com/backend/api/v1";

export const LOGIN_URL = "/user/login";

export const AUTH_TOKEN_NAME = "intellisafe-token";

export const USER_OBJECT_NAME = "user";

export const AUTH_ENABLED = true;

export const API_MOCKING_ENABLED = false;
